import Api from '@/services/Api'

export default {
  getActiveAnalytics() {
    return Api().get('/api/partner/getActiveAnalytics')
  },
  getMonthlyAnalytics() {
    return Api().get('/api/partner/getMonthlyAnalytics')
  },
  getSummaryAnalytics() {
    return Api().get('/api/partner/getSummaryAnalytics')
  }
}