<template>
  <div>
    <CircularProgress v-if="loader"/>
    <v-icon class="ml-2" size="20"> $rocketIcon </v-icon>   <span class="ml-2 sectionTitle">Active Orders</span>

    <v-row class="mt-2" no-gutters>
      <v-col cols="6">
        <v-card class="d-flex justify-center mr-2 mt-2" color="#434242" dark elevation="0">
            <v-list color="#434242">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-5" size="64"> $rupeeNoteIcon </v-icon>
              </span>

              <span class="d-flex justify-center mt-3 cardMetric">{{this.activeAnalytics.activeSales}}</span>
              <span class="d-flex justify-center mt-1 mb-2 cardTitle">Total Sales</span>
            </v-list>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card class="d-flex justify-center ml-2 mt-2" color="#434242" dark elevation="0">
            <v-list color="#434242">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-5" size="64"> $boxIcon </v-icon>
              </span>

              <span class="d-flex justify-center mt-3 cardMetric">{{this.activeAnalytics.activeOrders}}</span>
              <span class="d-flex justify-center mt-1 mb-2 cardTitle">Active Orders</span>
            </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- Monthly Section  -->

    <div class="ml-2 mt-4">
      <v-icon class="mr-2" size="20"> $rocketIcon </v-icon>   <span class="sectionTitle">{{moment().format('MMMM')}} Orders</span>
    </div>
    
    <v-row class="mt-2" no-gutters>
      <v-col cols="6">
        <v-card class="d-flex justify-center mr-2 mt-2" color="#434242" dark elevation="0">
            <v-list color="#434242">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-5" size="64"> $rupeeNoteIcon </v-icon>
              </span>

              <span class="d-flex justify-center mt-3 cardMetric">{{this.monthlyAnalytics.totalMonthlySales}}</span>
              <span class="d-flex justify-center mt-1 mb-2 cardTitle">Total Sales</span>
            </v-list>
        </v-card>
        
      </v-col>

      <v-col cols="6">
        <v-card class="d-flex justify-center ml-2 mt-2" color="#434242" dark elevation="0">
            <v-list color="#434242">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-5" size="64"> $boxIcon </v-icon>
              </span>

              <span class="d-flex justify-center mt-3 cardMetric">{{this.monthlyAnalytics.totalMonthlyOrders}}</span>
              <span class="d-flex justify-center mt-1 mb-2 cardTitle">All Orders</span>
            </v-list>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row class="mt-2" no-gutters>
      <v-col cols="6">
        <v-card class="d-flex justify-center mr-2 mt-2" color="#434242" dark elevation="0">
            <v-list color="#434242">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-5" size="64"> $boxActiveIcon </v-icon>
              </span>

              <span class="d-flex justify-center mt-3 cardMetric">{{this.monthlyAnalytics.totalMonthlyDeliveries}}</span>
              <span class="d-flex justify-center mt-1 mb-2 cardTitle">Orders Delivered</span>
            </v-list>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card class="d-flex justify-center ml-2 mt-2" color="#434242" dark elevation="0">
            <v-list color="#434242">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-5" size="64"> $boxInactiveIcon </v-icon>
              </span>

              <span class="d-flex justify-center mt-3 cardMetric">{{this.monthlyAnalytics.totalMonthlyCancelled}}</span>
              <span class="d-flex justify-center mt-1 mb-2 cardTitle">Orders Cancelled</span>
            </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- All Section  -->

    <div class="ml-2 mt-4">
      <v-icon class="mr-2" size="20"> $rocketIcon </v-icon>   <span class="sectionTitle">Summary - All</span>
    </div>
    
    <v-row class="mt-2" no-gutters>
      <v-col cols="6">
        <v-card class="d-flex justify-center mr-2 mt-2" color="#434242" dark elevation="0">
            <v-list color="#434242">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-5" size="64"> $rupeeNoteIcon </v-icon>
              </span>

              <span class="d-flex justify-center mt-3 cardMetric">{{this.summaryAnalytics.totalSales}}</span>
              <span class="d-flex justify-center mt-1 mb-2 cardTitle">Total Sales</span>
            </v-list>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card class="d-flex justify-center ml-2 mt-2" color="#434242" dark elevation="0">
            <v-list color="#434242">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-5" size="64"> $boxIcon </v-icon>
              </span>

              <span class="d-flex justify-center mt-3 cardMetric">{{this.summaryAnalytics.totalOrders}}</span>
              <span class="d-flex justify-center mt-1 mb-2 cardTitle">All Orders</span>
            </v-list>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row class="mt-2" no-gutters>
      <v-col cols="6">
        <v-card class="d-flex justify-center mr-2 mt-2" color="#434242" dark elevation="0">
            <v-list color="#434242">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-5" size="64"> $boxActiveIcon </v-icon>
              </span>

              <span class="d-flex justify-center mt-3 cardMetric">{{this.summaryAnalytics.totalDeliveries}}</span>
              <span class="d-flex justify-center mt-1 mb-2 cardTitle">Orders Delivered</span>
            </v-list>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card class="d-flex justify-center ml-2 mt-2" color="#434242" dark elevation="0">
            <v-list color="#434242">
              <span class="d-flex justify-center">
              <v-icon class="ml-2 mt-5" size="64"> $boxInactiveIcon </v-icon>
              </span>

              <span class="d-flex justify-center mt-3 cardMetric">{{this.summaryAnalytics.totalCancelled}}</span>
              <span class="d-flex justify-center mt-1 mb-2 cardTitle">Orders Cancelled</span>
            </v-list>
        </v-card>
      </v-col>
    </v-row>

    
  </div>
</template>
<script>
import analyticsServices from '@/services/analyticsServices'
import CircularProgress from '../loaders/CircularProgress.vue'
import moment from 'moment'

export default {
  components: {
    CircularProgress
  },
  data () {
    return {
      moment: moment,
      loader: false, 
      activeAnalytics: '',
      monthlyAnalytics: '',
      summaryAnalytics: '',
    }
  },
  async mounted() {
    this.loader = true
    const getActiveAnalytics = (await analyticsServices.getActiveAnalytics()).data;
    this.activeAnalytics = getActiveAnalytics.activeAnalytics

    const getMonthlyAnalytics = (await analyticsServices.getMonthlyAnalytics()).data;
    this.monthlyAnalytics = getMonthlyAnalytics.monthlyAnalytics

    const getSummaryAnalytics = (await analyticsServices.getSummaryAnalytics()).data;
    this.summaryAnalytics = getSummaryAnalytics.summaryAnalytics

    this.loader = false

  }
}
</script>
<style scoped>
.cardMetric{
font-family: Montserrat;
font-style: normal;
font-weight: 900;
font-size: 24px;
color: #C7C6C6;
}
.cardTitle{
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 12px;
color: #C7C6C6;
}
.sectionTitle {
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 16px;
color: rgba(255, 255, 255, 0.8);
}
</style>